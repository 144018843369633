import dayjs from 'dayjs'
import { PRINCIPAL_LIST, PROOF_TYPE, PROOF_STATUS, VISIT_TYPE, ROSTER_STATUS, ROSTER_TYPE, FIELD_TYPE } from './constants'
export default {
  dateFormat: (date, format = 'YYYY-MM-DD HH:mm:ss') => {
    if (!date) return '-'
    return dayjs(date).format(format)
  },
  hidePhone: (phone) => {
    if (!phone) {
      return ''
    } else {
      return phone.substr(0, 3) + '****' + phone.substr(7)
    }
  },
  hideIdCard: (idCard) => {
    if (!idCard) {
      return ''
    } else {
      return idCard.substr(0, 4) + '************' + idCard.substr(16)
    }
  },
  authStatus: (value) => {
    if (value === 0) {
      return '未认证'
    } else {
      return '已认证'
    }
  },
  orderStatus: (value) => {
    return ['待支付', '已付款', '已取消', '超时关闭', '已退单', '退款中'][value] || '-'
  },
  payType: (value) => {
    return ['-', '微信支付', '支付宝支付', '对公转帐', '商品兑换码', '其它'][value] || '-'
  },
  orderType: (value) => {
    return ['线上下单', '手动录入'][value - 1] || '-'
  },
  money: (value, showUnit = true) => {
    return (showUnit ? '¥' : '') + (value / 100).toThousand(2)
  },
  sizeFormat: value => {
    if (value > 1024 * 1024) {
      return `${(value / (1024 * 1024)).toFixed(1)}M`
    } else {
      return `${(value / (1024)).toFixed(1)}KB`
    }
  },
  serviceStatus (status) {
    return [0, 1, 3].includes(status) ? '服务中' : status === 2 ? '待确收' : '已完成'
  },
  serviceOrderStatus (status) {
    // :0-待支付、1-已付款(正常)、2-已取消、3-超时关闭、4-已退款
    return ['待支付', '已付款', '已取消', '超时关闭', '已退款'][status]
  },
  serviceType (type) {
    // 1-合同代写、2-合同审核、3-合同修改
    return ['-', '合同代写', '合同审核', '合同修改'][type] || '-'
  },
  writCategoryName (id, list) {
    const getName = function (id) {
      const row = list.find(row => row.id === id)
      if (row) {
        const name = getName(row.parentId)
        return (name ? name + '/' : '') + row.title
      } else {
        return ''
      }
    }
    return getName(id) || '-'
  },
  questionRiskLevel (value) {
    return ['无', '低风险', '中风险', '高风险', '超高风险'][value] || '-'
  },
  contractReviewStatus (value) {
    return ['待审批', '审批通过', '已驳回', '已撤回'][value] || '-'
  },
  performanceName (val) {
    return ['', '我方', '对方', '双方', '任一方'][val] || '-'
  },
  performanceStatus (val) {
    return ['待履约', '履约中', '已完成', '已终止', '已违约'][val] || '-'
  },
  timeLong (val, isSecond = false) {
    const dayTime = 60 * 24 * 60 * (isSecond ? 1 : 1000)
    const hourTime = 60 * 60 * (isSecond ? 1 : 1000)
    const minuteTime = 60 * (isSecond ? 1 : 1000)
    // const secondTime = (isSecond ? 1 : 1000)
    const day = Math.floor(val / dayTime)
    const hour = Math.floor((val - day * dayTime) / hourTime)
    const minute = Math.floor((val - day * dayTime - hour * hourTime) / minuteTime)
    //  const second = Math.floor((val - day * dayTime - hour * hourTime - minute * minuteTime) / secondTime)
    let str = ''
    // if (second) {
    //   str = `${second}秒`
    // }
    if (minute) {
      str = `${minute}分` + str
    }
    if (hour) {
      str = `${hour}时` + str
    }
    if (day) {
      str = `${day}天` + str
    }
    return str
  },
  secondFormat (second) {
    const sInt = parseInt(second)
    const hh = parseInt(sInt / 3600)
    let mm = parseInt((sInt % 3600) / 60)
    let ss = sInt % 60
    if (sInt < 60) {
      return sInt + ' 秒'
    } else if (sInt >= 60 && sInt < 3600) {
      if (ss === 0) {
        ss = ''
      } else {
        ss += ' 秒'
      }
      return mm + ' 分' + ss
    } else {
      if (mm === 0 && ss === 0) {
        mm = ''
        ss = ''
      } else {
        mm = (mm === 0 ? 0 : mm) + ' 分'
        ss = ss === 0 ? '' : ss + ' 秒'
      }
      return hh + ' 小时' + mm + ss
    }
  },
  timeLiness (val) {
    return ['-', '现已生效', '现已失效', '尚未生效', '已被修改', '部分失效'][val] || '-'
  },
  signStatus (val) {
    return ['已作废', '创建中', '签署中', '已逾期', '已完成'][val]
  },
  principal (val) {
    return PRINCIPAL_LIST[val] || '-'
  },
  proofType (val) {
    return PROOF_TYPE[val] || '-'
  },
  proofStatus (val) {
    return PROOF_STATUS[val] || '-'
  },
  orgaType (value) {
    return ['法人企业', '个体工商户', '其它组织'][value] || '-'
  },
  signFlowStatus (value) {
    // 0-无,默认(可发起审批)、1-审批中、2-审批通过、3-审批拒绝(可发起审批)
    return ['-', '审批中', '审批通过', '审批拒绝'][value] || '-'
  },
  VisitType (value) {
    return VISIT_TYPE[value] || '-'
  },
  rosterStatus (value) {
    return value ? ROSTER_STATUS[parseInt(value)] || '-' : '-'
  },
  rosterType (value) {
    return ROSTER_TYPE[value] || '-'
  },
  fieldType (value) {
    return FIELD_TYPE[value] || '-'
  }
}
