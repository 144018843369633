export default [
  {
    path: '/hr',
    redirect: '/hr/overview',
    meta: {
      id: '3ee0222e-ff13-424e-a95d-c449058ac8b8',
      icon: '',
      name: 'hr',
      title: '人事合规',
      type: [2],
      apis: []
    },
    children: [
      // {
      //   path: '/overview',
      //   meta: {
      //     id: '',
      //     icon: 'icon-zonglanicon',
      //     name: 'overview',
      //     title: '总览',
      //     iconColor: '#507BFD',
      //     type: [2],
      //     apis: []
      //   },
      //   component: () => import('@/webapp/hr/overview.vue')
      // },
      // {
      //   path: '/roster',
      //   meta: {
      //     id: '',
      //     icon: 'icon-zonglanicon',
      //     name: 'roster',
      //     title: '花名册',
      //     iconColor: '#507BFD',
      //     type: [2],
      //     apis: []
      //   },
      //   component: () => import('@/webapp/hr/roster.vue')
      // },
      {
        path: '/institution',
        meta: {
          id: '94bbd4e1-d40f-4c37-9b2b-c87c673bc282',
          icon: 'icon-zonglanicon',
          name: 'institution',
          title: '人事制度',
          iconColor: '#507BFD',
          type: [2],
          apis: []
        },
        component: () => import('@/webapp/hr/institution.vue')
      },
      {
        path: '/employee-manual',
        meta: {
          id: '',
          icon: 'icon-zonglanicon',
          name: 'employeeManual',
          title: '员工手册',
          iconColor: '#507BFD',
          type: [2],
          apis: [],
          hide: true
        },
        component: () => import('@/webapp/hr/employee-manual.vue')
      },
      {
        path: '/contract-list',
        meta: {
          id: '98bf39ca-ade4-4011-ad9f-2f563d2bad19',
          icon: 'icon-zonglanicon',
          name: 'contract-list',
          title: '人事合同',
          iconColor: '#507BFD',
          type: [2],
          apis: []
        },
        component: () => import('@/webapp/hr/contract-list.vue')
      }
      // {
      //   path: '/manage',
      //   meta: {
      //     id: '',
      //     icon: 'icon-zonglanicon',
      //     name: 'contract-list',
      //     title: '人事管理',
      //     iconColor: '#507BFD',
      //     type: [2],
      //     apis: []
      //   },
      //   children: [
      //     {
      //       path: '/entry',
      //       meta: {
      //         id: '',
      //         name: 'entry',
      //         title: '入职管理',
      //         type: [2],
      //         apis: []
      //       },
      //       component: () => import('@/webapp/hr/manage/entry.vue')
      //     },
      //     {
      //       path: '/regular',
      //       meta: {
      //         id: '',
      //         name: 'regular',
      //         title: '转正管理',
      //         type: [2],
      //         apis: []
      //       },
      //       component: () => import('@/webapp/hr/manage/regular.vue')
      //     },
      //     {
      //       path: '/dimission',
      //       meta: {
      //         id: '',
      //         name: 'dimission',
      //         title: '离职管理',
      //         type: [2],
      //         apis: []
      //       },
      //       component: () => import('@/webapp/hr/manage/dimission.vue')
      //     },
      //     {
      //       path: '/contract',
      //       meta: {
      //         id: '',
      //         name: 'contract',
      //         title: '合同管理',
      //         type: [2],
      //         apis: []
      //       },
      //       component: () => import('@/webapp/hr/manage/contract.vue')
      //     }
      //   ]
      // },
      // {
      //   path: '/setting',
      //   meta: {
      //     id: '',
      //     icon: 'icon-zonglanicon',
      //     name: 'setting',
      //     title: '设置',
      //     iconColor: '#507BFD',
      //     type: [2]
      //   },
      //   children: [
      //     {
      //       path: '/employee-field',
      //       meta: {
      //         id: '',
      //         name: 'employee-field',
      //         title: '员工字段',
      //         type: [2]
      //       },
      //       component: () => import('@/webapp/hr/setting/employee-field')
      //     }
      //   ]
      // }
    ]
  }
]
