const hasNoIdFromChildren = (menu, userType) => {
  if (!menu.children || menu.children.length === 0) {
    return !menu.id && menu.allowUserTypes.includes(userType)
  } else {
    return menu.children.some((row) => hasNoIdFromChildren(row, userType))
  }
}

export default {
  /**
   * 根据路由配置获取菜单数据
   * @param {*} routers 路由配置
   * @param {*} root 根路径，如果没有就以 '/' 为根路径
   * @param {function} permissionCb 更具权限id判断权限
   * @param {number} userType 1 个人用户 2 企业用户
   * @param {boolean} allowTest 是否允许参与测试，如果菜单有 meda.test 为 true ，表示此菜单参与上线测试使用
   */
  getMenus (routers, root, permissionCb, userType, allowTest) {
    const menus = []
    routers.forEach((row, index) => {
      if (!row.meta) return
      let path = row.path
      if (path.startsWith('/')) {
        path = path.substring(1)
      }
      const aPath = (root || '') + '/' + path
      const allowUserTypes = (row.meta.type || [0]).includes(0)
        ? [1, 2]
        : row.meta.type
      const menu = {
        id: row.meta.id,
        allowUserTypes,
        // 菜单名称
        title:
          row.meta.titleExt && row.meta.titleExt.length >= 2
            ? row.meta.titleExt[userType - 1]
            : row.meta.title,
        // 菜单图标,
        icon: row.meta.icon,
        iconColor: row.meta.iconColor || '#000',
        // 访问路径
        path: aPath,
        hide: row.meta.hide,
        expand: true,
        option: row.meta.option,
        component: row.component,
        shortTitle: row.meta.shortTitle,
        link: row.meta.link, // 外链
        target: row.meta.target || '_blank', // 外链打开方式
        badge: row.meta.badge, // 提示
        children:
          row.children && row.children.length > 0
            ? this.getMenus(
              row.children,
              aPath,
              permissionCb,
              userType,
              allowTest
            )
            : [] // 子菜单
      }
      if (!menu.hide) {
        if (userType === 1) {
          // 个人用户情况下, 不做权限判断， 只做菜单从属关系判断
          if (allowUserTypes.includes(userType)) {
            menus.push(menu)
          }
        } else if (userType === 2) {
          // 企业用户情况下
          if (
            allowUserTypes.includes(userType) &&
            (!menu.id ||
              permissionCb(menu.id) ||
              hasNoIdFromChildren(menu, userType))
          ) {
            menus.push(menu)
          }
        }
      }
      // if ((!menu.id || hasNoIdFromChildren(menu) || permissionCb(menu.id)) && allowUserTypes.includes(userType) && (!row.meta.test || allowTest)) {
      //   menus.push(menu)
      // }
    })
    return menus
  }
}
