import GreyList from './gray-list'
import WhiteList from './white-list'
import homeRoute from './home'
import compliance from './compliance'
import serviceRoute from './service'
import systemRoute from './system'
import assessment from './assessment'
import classroom from './classroom'
import hr from './hr'

// 注： meta.type 值代表某种类型的用户支持的路由
//  0 所有角色都支持 1 个人 2 公司

const routerList = [...homeRoute, ...assessment, ...compliance, ...hr, ...classroom, ...serviceRoute, ...systemRoute]

export default { GreyList, WhiteList, routerList }
